define('ordersMainRouter',[
  'module',
  'require',
  'windows',
  'ordersDetailView',
  'jquery',
  'logger',
  'underscore',
  'marionette',
  'app',
  'contentShowController',
  'itemPagesNameMixin',
  'entities/orders',
  'entities/customers/customers'
], function (
  Module,
  require,
  Windows,
  DetailView,
  $,
  Logger,
  _,
  Marionette,
  App,
  ContentShowController,
  ItemPagesNameMixin,
  Orders
) {
  'use strict';

  var OrdersRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'orders': 'showOrdersList',
      'offers': 'showOffersList',
      'orders_kanban/filter/:endUserId': 'showOrdersBoardFiltered',
      'offers_kanban/filter/:endUserId': 'showOffersBoardFiltered',
      'orders_kanban(/:tabName)': 'showOrdersBoard',
      'offers_kanban(/:tabName)': 'showOffersBoard',

      'orders/(customers/:customerSecId/orders/):orderSecId': 'showDefaultTab',
      'orders/(customers/:customerSecId/orders/):orderSecId(/:tabName)': 'showTab',
      'orders/(customers/:customerSecId/producttemplates/):orderSecId': 'showDefaultTab',
      'orders/(customers/:customerSecId/producttemplates/):orderSecId(/:tabName)': 'showTab'
    }
  });

  Module.exports = OrdersRouter;

  var API = {
    showOrdersList: function () {
      require([
        'ordersListView'
      ], function (View) {
        ContentShowController.showContent().done(function () {
          App.currentUrl = App.getCurrentRoute();
          var view = new View({type: 'command'});
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS_LIST);
        });
      });
    },

    showOffersList: function () {
      require([
        'ordersListView'
      ], function (View) {
        ContentShowController.showContent().done(function () {
          App.currentUrl = App.getCurrentRoute();
          var view = new View({type: 'offer'});
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS_LIST);
        });
      });
    },

    showDefaultTab: function (customerSecId, poi) {
      API.showTab(customerSecId, poi, 'details');
    },

    showTab: function (customerSecId, poi, tab) {
      Orders.showOrder(poi, tab, true);
    },

    showOrdersBoardFiltered: function (endUserId) {
      this.showOrdersBoard(null, endUserId);
    },

    showOffersBoardFiltered: function (endUserId) {
      this.showOffersBoard(null, endUserId);
    },

    showOrdersBoard: function (parentStatus, endUserId) {
      require([
        'ordersBoardView',
        'contentShowController'
      ], function (View, ContentShowController) {
        App.currentUrl = App.getCurrentRoute();

        if(endUserId)
          App.navigate('orders_kanban', {trigger: false});

        ContentShowController.showContent().done(_.bind(function () {
          var view = new View({
            parentStatus: parentStatus,
            endUserId: endUserId,
            type: 'command'
          });

          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS_BOARD);
        }, this));
      });
    },

    showOffersBoard: function (parentStatus, endUserId) {
      require([
        'ordersBoardView',
        'contentShowController'
      ], function (View, ContentShowController) {
        App.currentUrl = App.getCurrentRoute();

        if(endUserId)
          App.navigate('offers_kanban', {trigger: false});

        ContentShowController.showContent().done(_.bind(function () {
          var view = new View({
            parentStatus: parentStatus,
            endUserId: endUserId,
            type: 'offer'
          });

          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS_BOARD);
        }, this));
      });
    }
  };

  App.on('before:start', function () {
    new OrdersRouter({
      controller: API
    });
  });
});

