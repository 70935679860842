define('entities/product_picture',[
  'module',
  'backbone',
  'settings',
  'jquery',
  'app'
], function (
  module,
  Backbone,
  Settings,
  $,
  App
) {
  'use strict';

  var ProductPicture = Backbone.Model.extend({
    getImageUrl: function (width, height, productCode, logoUid) {
      var params = {
        mw: width,
        mh: height
      };

      if (logoUid) {
        params.fUid = logoUid;
      }

      return Settings.url(
        'x2png-ws',
        '/vrp/' + this.get('secId'),
        params
      );
    },

    destroy: function () {
      this.url = Settings.url('compuzz2', 'v2/productPictures/' + this.get('secId'));

      Backbone.Model.prototype.destroy.call(this);
    },

    idAttribute: 'secId'
  });

  var ProductPictureCollection = Backbone.Collection.extend({
    model: ProductPicture,
    _pageSize: 10,

    fetchByProduct: function (productSecId, init) {
      var that = this,
        params = {
          first: this.length ? this.length : '0',
          pageSize: this._pageSize
        }, url = Settings.url('compuzz2', 'v2/productPictures/' + productSecId, params),
        defer = $.Deferred();

      this.trigger('request');
      $.ajax({
        url: url,
        success: function (response) {
          if (response.length !== that._pageSize) {
            that.trigger('all:loaded');
          }
          that.push(response);
          that.trigger('sync', that);
          defer.resolve(that);
          if (init) {
            that.trigger('first:loaded');
          }
        }
      });

      return defer.promise();
    }

  });

  App.reqres.setHandler('product-picture:collection', function (collectionJSON) {
    return new ProductPictureCollection(collectionJSON);
  });

});
