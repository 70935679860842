
define('template!customersPopupEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="body">\n  <div class="main-info">\n    <div class="title">' +
((__t = ( _.i18n('orders.detail.client-title') )) == null ? '' : __t) +
'</div>    \n    <div class="columns">\n      <div class="column col1">      \n        <div class="item" data-field-name="endUserId">\n          <div class="libelle">' +
((__t = ( _.i18n('common.endUserId') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" class="form-control floating-label js-info-input js-end-user-id"\n                    data-field-name="endUserId" placeholder="' +
((__t = ( _.i18n('common.endUserId') )) == null ? '' : __t) +
'"\n                    value="' +
((__t = ( endUserId )) == null ? '' : __t) +
'" data-placement="top">\n          </div>\n        </div>\n        <div class="item checkbox-group" data-field-name="subscribed">\n          <div class="libelle">' +
((__t = ( _.i18n("common.subscribed") )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-2 m-t--7 m-b--5">\n            <input type="checkbox" style="width: 20px; height: 40px; " id="subscribed" class="subscribed" ' +
((__t = ( subscribed?'checked':'')) == null ? '' : __t) +
'>\n          </div>\n        </div>\n        <div class="item checkbox-group" data-field-name="vip">\n          <div class="libelle">' +
((__t = ( _.i18n("common.vip") )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-2 m-t--7 m-b--5">\n            <input type="checkbox" style="width: 20px; height: 40px; " id="vip" class="vip" ' +
((__t = ( vip?'checked':'')) == null ? '' : __t) +
'>\n          </div>\n        </div>\n        <div class="item form-email" data-field-name="email">\n          <div class="libelle">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" id="emailInput" class="form-control floating-label js-info-input" data-field-name="email" placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'" value="' +
((__t = ( email )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="item form-email" data-field-name="invoiceEmail">\n          <div class="libelle">' +
((__t = ( _.i18n('common.invoiceEmail') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" id="invoiceEmailInput" class="form-control floating-label js-info-input" data-field-name="invoiceEmail" placeholder="' +
((__t = ( _.i18n('common.invoiceEmail') )) == null ? '' : __t) +
'" value="' +
((__t = ( invoiceEmail )) == null ? '' : __t) +
'">\n          </div>\n        </div>       \n        <div class="phone-number item" data-field-name="tel">\n          <div class="libelle">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="tel" placeholder="' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'" value="' +
((__t = ( tel )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="mobile-phone-number item" data-field-name="mobile">\n          <div class="libelle">' +
((__t = ( _.i18n('common.mobilePhone') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="mobile" placeholder="' +
((__t = ( _.i18n('common.mobilePhone') )) == null ? '' : __t) +
'" value="' +
((__t = ( mobile )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="item" data-field-name="companyNumber">\n          <div class="libelle">' +
((__t = ( _.i18n('common.companyNumber') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="companyNumber"\n                    placeholder="' +
((__t = ( _.i18n('common.companyNumber') )) == null ? '' : __t) +
'" value="' +
((__t = ( companyNumber )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        ';
 if (webshops) { ;
__p += '\n        <div class="item" data-field-name="webShopLocale">\n          <div class="libelle">' +
((__t = ( _.i18n('common.webshop') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <select class="form-control js-info-input js-popup-input" data-field-name="webShopLocale" value="' +
((__t = ( webShopLocale )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('common.webshop') )) == null ? '' : __t) +
'">\n              <option value=""></option>\n              ';
 _.each(webshops, function(w) { ;
__p += '\n              <option value="' +
((__t = ( w.code )) == null ? '' : __t) +
'" ' +
((__t = ( w.code===webShopLocale?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( w.code + ' (' + w.value + ')' )) == null ? '' : __t) +
'</option>\n              ';
 }); ;
__p += '\n            </select>\n          </div>\n        </div>\n        ';
 } ;
__p += '\n      </div>\n      <div class="column col2">\n        <div class="item">\n          <div class="libelle">' +
((__t = ( _.i18n('userInfo.logo') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <div class="profile-logo-container">\n              <div class="_texture-bg _keep-img-link profile-img-block">\n                <img class="logo-holder js-logo-holder clickable transparent-bg">\n              </div>\n              <div class="logo-holder js-logo-holder col-xs-2 clickable default-upload-image hidden"></div>\n              <div class="logo-editor">\n                <label for="change-logo-input">\n                  <i class="mdi-file-file-upload clickable"></i>\n                </label>\n                <label>\n                  <i class=\'mdi-action-delete js-remove-logo clickable\'></i>\n                </label>\n                <input type="file" class="hidden js-change-logo" id="change-logo-input" accept="image/*">\n              </div>\n            </div>\n          </div>\n        </div>    \n        <div class="item checkbox-group" data-field-name="subjectToVat">\n          <div class="libelle">' +
((__t = ( _.i18n("common.subjectToVat") )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="checkbox" style="width: 20px; height: 40px; " id="subjectToVat" class="subjectToVat" ' +
((__t = ( subjectToVat ? 'checked' : '' )) == null ? '' : __t) +
'>\n          </div>\n        </div>\n        <div class="item siret-ctn" data-field-name="siret">\n          <div class="libelle">' +
((__t = ( _.i18n('common.siret') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" autocomplete="no" class="form-control floating-label js-info-input siret-input"\n                    data-field-name="siret" placeholder="' +
((__t = ( _.i18n('common.siret') )) == null ? '' : __t) +
'" value="' +
((__t = ( siret )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="item kvk-ctn" data-field-name="kvk">\n          <div class="libelle">' +
((__t = ( _.i18n('common.kvk') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" autocomplete="no" class="form-control floating-label js-info-input siret-input"\n                    data-field-name="siret" placeholder="' +
((__t = ( _.i18n('common.kvk') )) == null ? '' : __t) +
'" value="' +
((__t = ( siret )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <div class="item vat-area" data-field-name="vatNumber">\n          <div class="libelle">' +
((__t = ( _.i18n('common.vatNumber') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" class="form-control floating-label js-info-input uppercase-input vat-input"\n                    id="vat-input"\n                    data-field-name="vatNumber" placeholder="' +
((__t = ( _.i18n('common.vatNumberWithEntry') )) == null ? '' : __t) +
'"\n                    value="' +
((__t = ( vatNumber )) == null ? '' : __t) +
'">\n            <span class="checking-vat-number" style="display:none;color:#85ac85;animation: fade 1s forwards;">' +
((__t = ( _.i18n('common.checking') )) == null ? '' : __t) +
'</span>\n            <span class="valid-vat-number" style="display:none;color:green;">' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'</span>\n            <span class="invalid-vat-number"\n                  style="display:none;color:orange;">' +
((__t = ( _.i18n('common.invalidVatNumber') )) == null ? '' : __t) +
'</span>\n            <span class="vat-server-not-reachable" style="display:none;color:orange;">' +
((__t = ( _.i18n('common.vat-server-not-reachable') )) == null ? '' : __t) +
'</span>\n          </div>\n          <div class="vat-from-siret">\n            <button class="btn-vat-from-siret" style="font-size: 10px;">Calc from siret</button>\n          </div>\n        </div>    \n        <div class="item" data-field-name="subActivity">\n          <div class="libelle" for="select-sub-activity">' +
((__t = ( _.i18n('endUsersDetail.subActivity') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input id="select-sub-activity" value="' +
((__t = ( subActivity )) == null ? '' : __t) +
'"\n                  class="form-control js-info-input js-popup-input js-sub-activity-input" data-field-name="subActivity">\n            <div class="sub-activity-autocomplete"></div>\n          </div>\n        </div>    \n        <div class="item" data-field-name="mainActivity">\n          <div class="libelle">' +
((__t = ( _.i18n('endUsersDetail.mainActivity') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <select name="" id="preselect-activite-sousClasse" data-field-name="mainActivity"\n                    class="form-control js-info-input js-popup-input js-activity-input">\n              <option value="-1"></option>\n              ';
 _.each(mainActivities, function(activity) { ;
__p += '\n              <option data-class="' +
((__t = ( (activity.code) )) == null ? '' : __t) +
'"\n              ' +
((__t = ( mainActivity === activity.value ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( activity.value )) == null ? '' : __t) +
'</option>\n              ';
 }); ;
__p += '\n            </select>\n          </div>\n        </div>\n        <div class="item" data-field-name="comment">\n          <div class="libelle">' +
((__t = ( _.i18n('endUsersDetail.endUserComments') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="comment"\n                      placeholder="' +
((__t = ( _.i18n('endUsersDetail.endUserComments') )) == null ? '' : __t) +
'">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n          </div>\n        </div>   \n        <div class="item" data-field-name="locales">\n          <div class="libelle">' +
((__t = ( _.i18n('endUsersDetail.language') )) == null ? '' : __t) +
'</div>\n          <div class="value dropdown">\n            <button class="form-control dropdown-toggle dropdown__button" type="button" id="dropdownMenu-lang"\n                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n              <span class="pull-left dropdown__label lang-label">None</span>\n              <span class="caret pull-right m-t-5 dropdown__caret"></span>\n            </button>\n            <ul class="dropdown-menu dropdown-menu-lang" role="menu" aria-labelledby="dropdownMenu-lang">\n              <li role="presentation"><a role="menuitem" href="#">EN</a></li>\n              <li role="presentation"><a role="menuitem" href="#">FR</a></li>\n              <li role="presentation"><a role="menuitem" href="#">NL</a></li>\n              <li role="presentation"><a role="menuitem" href="#">DE</a></li>\n              <li role="presentation"><a role="menuitem" href="#">ES</a></li>\n              <li role="presentation"><a role="menuitem" href="#">IT</a></li>\n            </ul>\n          </div>\n        </div>  \n        <div class="item" data-field-name="webSite">\n          <div class="libelle">' +
((__t = ( _.i18n('common.webSite') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="webSite" placeholder="' +
((__t = ( _.i18n('common.webSite') )) == null ? '' : __t) +
'" value="' +
((__t = ( webSite )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        ';
 if(showPaymentTerms !== '') { ;
__p += '\n          <div class="item" data-field-name="paymentTerms">\n            <div class="libelle">' +
((__t = ( _.i18n('orders.paymentDelay') )) == null ? '' : __t) +
'</div>\n            <div class="value">\n              <input type="number" class="form-control floating-label js-info-input" data-field-name="paymentTerms"\n                     value="' +
((__t = ( paymentTerms )) == null ? '' : __t) +
'">\n            </div>\n          </div>\n        ';
 } ;
__p += '\n      </div>\n    </div>\n  </div>\n \n  <div class="addresses">\n    <div class="invoice column">\n      <div class="title">' +
((__t = ( _.i18n('endUsersDetail.invoiceAddress') )) == null ? '' : __t) +
'</div>    \n      <div class="item" data-field-name="name">\n        <div class="libelle">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n      </div>   \n      <div class="item" data-field-name="firstName">\n        <div class="libelle">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="item" data-field-name="lastName">\n        <div class="libelle">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="lastName" placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n        </div>\n      </div>  \n      <div class="item invoice-addressL1" data-field-name="addressL1">\n        <div class="libelle">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="addressL1" placeholder="' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL1 )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="item invoice-addressL2" data-field-name="addressL2">\n        <div class="libelle">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="addressL2" placeholder="' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL2 )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="item invoice-houseNumber" data-field-name="houseNumber">\n        <div class="libelle">' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="houseNumber" placeholder="' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'" value="' +
((__t = ( houseNumber )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="item city">\n        <div class="libelle">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n          <div class="invoice-zip" data-field-name="zip">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="zip" placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip )) == null ? '' : __t) +
'">\n          </div>\n          <div class="invoice-city" data-field-name="city">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="city" placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n      </div>\n      <div class="item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n          <div class="js-select-countries-region"></div>\n        </div>\n      </div>\n    </div>\n    <div class="delivery column">\n      <div class="title-container">\n        <div class="title">' +
((__t = ( _.i18n('endUsersDetail.refDeliveryAddress') )) == null ? '' : __t) +
'</div>        \n        <div class="js-copy-address">' +
((__t = ( _.i18n('endUsersDetail.copyInvoiceAddress') )) == null ? '' : __t) +
'</div>\n      </div>      \n      <div class="item delivery-name" data-field-name="name">\n          <div class="libelle">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n              <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.name )) == null ? '' : __t) +
'">\n          </div>\n      </div>\n      <div class="item delivery-firstname" data-field-name="firstName">\n          <div class="libelle">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n              <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.firstName )) == null ? '' : __t) +
'">\n          </div>\n      </div>\n      <div class="item delivery-lastname" data-field-name="lastName">\n          <div class="libelle">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n              <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="lastName" placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.lastName )) == null ? '' : __t) +
'">\n          </div>\n      </div>\n      <div class="item delivery-addressL1" data-field-name="addressL1">\n          <div class="libelle">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n              <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="addressL1" placeholder="' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.addressL1 )) == null ? '' : __t) +
'">\n          </div>\n      </div>\n      <div class="item delivery-addressL2" data-field-name="addressL2">\n          <div class="libelle">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n              <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="addressL2" placeholder="' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.addressL2 )) == null ? '' : __t) +
'">\n          </div>\n      </div>\n      <div class="item delivery-houseNumber" data-field-name="houseNumber">\n          <div class="libelle">' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n              <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="houseNumber" placeholder="' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.houseNumber )) == null ? '' : __t) +
'">\n          </div>\n      </div>\n      <div class="item city">\n          <div class="libelle">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</div>\n          <div class="value">\n              <div class="delivery-zip" data-field-name="zip">\n                  <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="zip" placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.zip )) == null ? '' : __t) +
'">\n              </div>\n              <div class="delivery-city" data-field-name="city">\n                  <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="city" placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.city )) == null ? '' : __t) +
'">\n              </div>\n          </div>\n      </div>   \n      <div class="item delivery-country-code">\n        <div class="libelle">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</div>\n        <div class="value">\n          <div class="js-select-countries-delivery-region"></div>\n        </div>\n      </div>\n    </div>  \n  </div>  \n \n</div>\n<div class="footer">\n  <div class="left">\n    <div class="loader-2"></div>\n    <div class="error">\n      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>\n      <div class="message">qsdqdqsdqsdqdqsdqsdqs dq sdqsd qsdqsd qsd sdf sdfsdf sd f sdf sdf sdfsdfsdfsdfsdfsdfsdfsdfsdfsdfs dfsdfsdfsdfsdf</div>\n    </div>\n  </div>\n  <div class="right">\n    <div class="cancel-button-region"></div>\n    <div class="confirm-button-region"></div>\n  </div>\n</div>';

}
return __p
};});

