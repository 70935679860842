
define('template!ordersDetailOrderStatusView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n  <div class="title">' +
((__t = ( _.i18n('orders.detail.status-title') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="body">\n  <div class="column">\n    <div class="type item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">\n        <div class="types-region"></div>\n      </div>\n    </div>\n    <div class="creation-date item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.date.create') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value"></div>\n    </div>\n    <div class="due-date item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('order.expShippingDate') )) == null ? '' : __t) +
'</div>\n        <div class="tools-ctn">\n          <div class="tools">\n            <div class="tool-item">\n              <svg class="btn-edit-expedition-date" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="value exp-shipping-date"></div>\n    </div>\n    <div class="tracking-status item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('orders.shippingStatus') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value"></div>\n    </div>      \n    <div class="payment-method item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('order.paymentMethod') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value"></div>\n    </div> \n    <div class="item validation-date">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('order.validationDate') )) == null ? '' : __t) +
'</div>\n        <div class="tools-ctn">\n          <div class="tools">\n            <div class="tool-item">\n              <svg class="btn-edit-validation-date" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="value validation-date"></div>\n    </div>\n    <div class="delivery-date item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.deliveryDate') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value"></div>\n    </div>\n  </div>\n  <div class="column right-col">\n    <div class="status item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">\n        <div class="statuses-region"></div>\n      </div>\n    </div>\n    <div class="item supplier-number">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('orders.supplierNumber') )) == null ? '' : __t) +
'</div>\n        <div class="tools-ctn">\n          <div class="tools">\n            <div class="tool-item">\n              <svg class="btn-edit-supplier-number" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>\n            </div>\n            <div class="tool-item">\n              <svg class="btn-copy-supplier-number" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="value"></div>\n    </div>         \n    <div class="item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('order.deadlineDate') )) == null ? '' : __t) +
'</div>\n        <div class="tools-ctn">\n          <div class="tools">\n            <div class="tool-item">\n              <svg class="btn-edit-deadline-date" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="value deadline-date"></div>\n    </div>          \n    <div class="tracking item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('order.trackings') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value">\n        <div class="trackings-region"></div>\n      </div>\n    </div>   \n    <div class="payment-link item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('order.transactionNr') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value"></div>\n    </div>\n    <div class="external-id item">\n      <div class="libelle">\n        <div class="text">' +
((__t = ( _.i18n('order.externalId') )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="value"></div>\n    </div>\n    <div class="customer-reference-region"></div>\n  </div>\n</div>';

}
return __p
};});

