define('orderDetailCompositionView',[
  'module',
  'marionette',
  'settings',
  'app',
  'jquery',
  'bootbox',
  'noItemsView',
  'template!orderCompositionTabTpl',
  'template!orderCompositionItemTpl',
  'compuzzProUtils',
  'underscore',
  'rolesMixin',
  'vectorizationCollectionView',
  'similarCollectionView',
  'googleImageCollectionView',
  'ordersDetailCompositionProofsheetView',
  'entities/orders',
  'entities/composition_vectorization'
], function (
  module,
  Marionette,
  Settings,
  App,
  $,
  bootbox,
  NoItemsView,
  compositionTabTpl,
  compositionItemTpl,
  CompuzzProUtils,
  _,
  RolesMixin,
  VectorizationCollectionView,
  SimilarCollectionView,
  GoogleImageCollectionView,
  CompositionProofsheetView
) {
  'use strict';

  var CompositionItemView = Marionette.LayoutView.extend({
    letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
      'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    template: compositionItemTpl,

    ui: {
      compositionImg: '.composition-img',
      useBackgroundColor: '.useBackgroundColor',
      jsLoader: '.js-loading-block',
      btnLoadGoogleImage: '.btn-load-google-images'
    },

    regions: {
      vectorizationRegion: '.vectorization-region',
      similarRegion: '.similar-region',
      googleImageRegion: '.google-image-region'
    },

    events: {
      'click .btn-edit-compuzzpro': 'onCompuzzProEditClick',
      'change @ui.useBackgroundColor': 'onChangeUseBackgroundColor',
      'click .btn-edit-property': 'onClickEditProperty',
      'click .btn-connect-compuzzpro': 'onBtnConnectCompuzzProClick',
      'click .btn-delete-print-area': 'onDeletePrintArea',
      'click @ui.btnLoadGoogleImage': 'onLoadGoogleImages',
      'click .btn-check-stroke': 'onCheckStrokeClick'
    },

    onCheckStrokeClick: function () {
      this.checkStroke = true;
      $.ajax({
        url: Settings.url('compuzz2', 'personalization.json/compositions/' + this.model.get('secId') + '/checkstroke', {
          strokeWidth: this.minStrokeWidth,
          strokeLength: this.minStrokeWidth,
          strokeWidthTexts: this.minStrokeWidth,
          strokeLengthTexts: this.minStrokeWidth,
          deviation: 0.3,
          step: 0.3
        }),
        type: 'GET',
        success: _.bind(function (result) {
          if (!result || !result.value) {
            bootbox.alert(_.i18n('common.noStrokeError'));
          }
        }, this),
        error: function () {
        }
      });
      this.render();
    },

    onBtnConnectCompuzzProClick: function (event) {
      var target = $(event.target);
      var currentHref = target.attr('href');
      if (currentHref.indexOf(':v2') !== -1) {
        target.attr('href', currentHref.replace(':v2', '-v2'));
      } else {
        target.attr('href', currentHref.replace('-v2', ':v2'));
      }
    },

    onChangeUseBackgroundColor: function () {
      $('.loading-block').show();
      App.request('order:update-use-background-color', this.model.collection.orderModel.get('purchaseOrderId'), this.model.get('secId'),
        this.ui.useBackgroundColor.prop('checked')).then(_.bind(function () {
        CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
        $('.loading-block').hide();
      }, this));
    },

    forceNotDesign2: false,

    onRender: function () {
      $.material.init();
      var useBackgroundColor = this.model.get('useBackgroundColor') || false;
      this.ui.useBackgroundColor.prop('checked', useBackgroundColor);
      /*
      if (this.forceNotDesign2 || !Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2] ||
        this.model.collection.orderModel.get('commandType') === 'offer') {
        var col = App.request('composition-vectorization:get-collection', this.model.get('secId'));
        col.fetch();
        this.vectorizationsView = new VectorizationCollectionView({
          collection: col
        });

        this.getRegion('vectorizationRegion').show(this.vectorizationsView);

        var col2 = App.request('composition-similar:get-collection', this.model.get('secId'));
        col2.fetch();
        this.similarsView = new SimilarCollectionView({
          collection: col2
        });

        this.getRegion('similarRegion').show(this.similarsView);
      }
       */
    },

    onLoadGoogleImages: function () {
      this.ui.jsLoader.show();
      this.ui.btnLoadGoogleImage.hide();
      var col3 = App.request('composition-google-image:get-collection', this.model.get('secId'));
      col3.fetch().done(_.bind(function () {
        this.googleImagesView = new GoogleImageCollectionView({
          collection: col3
        });
        this.getRegion('googleImageRegion').show(this.googleImagesView);
      }, this));
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.displayEditButtons = true;
     /* if (this.model.collection.orderModel.get('pim')) {
        templateData.displayEditButtons = false;
      }*/

      _.each(templateData.availablePzs, _.bind(function (pz) {
        if (pz.colorModeType !== 'spot') {
          pz.maxColors = '';
        }
        if (pz.colorModeType === 'quadri') {
          pz.digital = true;
        } else {
          pz.digital = '';
        }
        if (pz.colorModeType === 'fixedFilling') {
          pz.eng = true;
        } else {
          pz.eng = '';
        }
      }, this));

      templateData.tokenId = Settings.get('tokenId');
      if (Settings.compuzzAwsWsSend) {
        templateData.tokenId += ':v2';
      }
      templateData.customData = this.model.get('customData');
      templateData.prinAreaImgUrl = this.getPrintAreaImgUrl();
      templateData.printAreaName = this.model.get('paName') ? (this.getPrintAreaCode() + ' - ' +
        this.model.get('paName')) : this.getPrintAreaCode();
      templateData.printZone = this.model.get('pzId');

      templateData.compuzzProProtocol = 'cpzp';
      if (Settings.compuzzProProtocol) {
        templateData.compuzzProProtocol = Settings.compuzzProProtocol;
      }
      templateData.editorUrl = Settings.editorUrl;

      if (!this.forceNotDesign2 && Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2] &&
        this.model.collection.orderModel.get('commandType') !== 'offer') {
        templateData.notDesign2 = false;
      } else {
        templateData.notDesign2 = true;
      }

      templateData.displayCheckStroke = false;
      _.each(this.model.collection.productModel.get('printAreas'), _.bind(function (pa) {
        _.each(pa.printZones, _.bind(function (pz) {
          if (pz.id === this.model.get('pzId') && pz.minStrokeWidth) {
            this.minStrokeWidth = pz.minStrokeWidth;
            templateData.displayCheckStroke = true;
          }
        }, this));
      }, this));

      return templateData;
    },

    getPrintAreaCode: function () {
      if (Settings.get('currentCAccount').displayPaCodeLetter) {
        var letter = this.letters[parseInt(this.model.get('paCode'), 10) - 1];
        return letter ? letter : this.model.get('paCode');
      } else {
        return this.model.get('paCode');
      }
    },

    onCompuzzProEditClick: function () {
      CompuzzProUtils.getInstance().sendData({
        value: 'edit compo',
        type: 'compuzzProEditComposition',
        tokenId: Settings.get('tokenId'),
        compositionSecId: this.model.get('secId')
      });
    },

    onDeletePrintArea: function (e) {
      App.request('order:remove-print-zone', this.model.collection.orderModel.get('purchaseOrderId'),
        $(e.target).parent().attr('printzone')).done(_.bind(function () {
        this.model.collection.orderModel.fetch();
      }, this));
    },

    getPrintAreaImgUrl: function () {
      var params = {};
      if (!this.model.get('useBackgroundColor')) {
        params.nobg = true;
      }
      params.productCode = this.model.collection.orderModel.get('productBaseCode');
      params.pac = this.model.get('paCode');
      if (this.checkStroke) {
        if (this.minStrokeWidth) {
          params.strokeWidth = this.minStrokeWidth;
          params.strokeLength = this.minStrokeWidth;
          params.strokeWidthTexts = this.minStrokeWidth;
          params.strokeLengthTexts = this.minStrokeWidth;
        }
        params.checkStroke = true;
      }
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        return Settings.url('x2png', 'compositions/' + this.model.get('secId') + '/snapshot', params);
      } else {
        return Settings.url('x2png', 'compositions/' + this.model.get('secId') + '/snapshot/withMeasure', params);
      }
    }
  });

  var CompositionCollectionView = Marionette.CollectionView.extend({
    childView: CompositionItemView
  });

  var CompositionTabView = Marionette.LayoutView.extend({
    template: compositionTabTpl,
    className: 'content order-personalization',

    ui: {
      txtInternalComment: '.order-internal-comment'
    },

    regions: {
      prinAreaItem: '.js-print-area-container-region',
      proofsheetRegion: '.proofsheet-region'
    },

    events: {
      'click .js-delete-custom-export': 'onDeleteCustomExport',
      'click .btn-edit-compuzz': 'onCompuzzEditClick',
      'change @ui.txtInternalComment': 'onInternalCommentChanged',
      'change .itName': 'onPZchange',
      'change .paName': 'onAddPrintArea'
    },

    serializeData: function () {
      var templateData = this.orderModel.toJSON();
      if (templateData.exportFileUid) {
        templateData.customExportUrl = Settings.url('file', templateData.exportFileUid) + '&cmds=on';
      }

      templateData.comment = this.options.productModel.get('comment');
      if (!templateData.comment) {
        templateData.comment = '';
      }

      templateData.printAreas = [];
      var usedPrintArea = this.options.collection.orderModel.get('compositions');
      _.each(this.printAreas, function (availablePrintArea) {
        var pa = _.findWhere(usedPrintArea, {paId: availablePrintArea.id});
        if (!pa) {
          templateData.printAreas.push(availablePrintArea);
        }
      });

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2] &&
        this.options.collection.orderModel.get('commandType') !== 'offer') {
        templateData.notDesign2 = false;
      } else {
        templateData.notDesign2 = true;
      }

      return templateData;
    },

    onShow: function () {
      if (this.collection.models.length !== 0) {
        var availablePrAreas = this.options.productModel.get('printAreas');

        _.each(this.collection.models, function (model) {
          var pa = _.findWhere(availablePrAreas, {'id': model.get('paId')});
          var availablePrintZones = pa ? pa.printZones : [];
          model.set('availablePzs', availablePrintZones);
        });
        this.getRegion('prinAreaItem').show(new CompositionCollectionView({collection: this.collection}));
      } else {
        this.getRegion('prinAreaItem').show(new NoItemsView());
      }

      //-----------------

      this.proofsheetView = new CompositionProofsheetView({
        model: this.orderModel
      });
      this.getRegion('proofsheetRegion').show(this.proofsheetView);
    },

    onInternalCommentChanged: function () {
      $('.loading-block').show();
      this.orderModel.set('internalComment', this.ui.txtInternalComment.val());
      $.when(App.request('order:update', {
        purchaseOrderId: this.orderModel.get('purchaseOrderId'),
        internalComment: this.orderModel.get('internalComment')
      })).done(_.bind(function () {
        $('.loading-block').hide();
      }, this));
    },

    onDeleteCustomExport: function () {
      $('.loading-block').show();
      App.request('order:delete-custom-export', this.orderModel.get('purchaseOrderId')).then(_.bind(function () {
        this.orderModel.set('exportFileUid', null);
        CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
        $('.loading-block').hide();
      }, this));
    },

    onCompuzzEditClick: function (e) {
      var printZone = $(e.target).attr('printZone');
      var poi = this.collection.orderModel.id;
      this.trigger('editor:open', poi, printZone, Settings.editorUrl, this.collection.orderModel.get('pim'));
    },

    onPZchange: function (e) {
      App.request('order:update-printzone', this.orderModel.get('purchaseOrderId'),
        $(e.target).attr('currentPrintzone'),
        $(e.target).find(':selected').val(), null).then(
        _.bind(function () {
          this.orderModel.fetch();
          //window.location.reload();
        }, this)
      );
    },

    onAddPrintArea: function (e) {
      var pa = _.find(this.printAreas, function (pa) {
        return pa.id.toString() === $(e.target).find(':selected').val();
      });
      var pz = pa.printZones[0];
      App.request('order:add-printzone', this.orderModel.get('purchaseOrderId'), pz.id,
          (pz.colorModeType==='spot'?1:undefined)).then(
        _.bind(function () {
          this.orderModel.fetch();
          //window.location.reload();
        }, this)
      );
    }
  });

  module.exports = CompositionTabView;
});

