define('ordersDuplicateView',[
  'app',
  'module',
  'underscore',
  'backbone',
  'settings',
  'selectView',
  'checkboxView',
  'modalsConfirmationView',
  'usersView',
  'template!ordersDuplicateView',
  'entities/orders'
], function (
  App,
  module,
  _,
  Backbone,
  Settings,
  SelectView,
  CheckboxView,
  ModalsConfirmationView,
  UsersView,
  Tpl,
  Orders
) {
  'use strict';

  module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-duplicate-view ' + ModalsConfirmationView.prototype.className,

    regions: _.extend({
      userRegion: '.user-region',
      typesRegion: '.types-region',
      statusesRegion: '.statuses-region',
      copyCommentsRegion: '.copy-comments-region'
    }, ModalsConfirmationView.prototype.regions),

    initialize: function () {
      this.type = this.model.get('commandType');
      this.status = this.model.get('status');
      this.statusesCollection = new Backbone.Collection();

      //----------------
      this.userpid = null;

      _.each(this.model.get('userpids'), _.bind(function(userpid) {
        if(userpid.secId === Settings.get('currentUser').secId)
          this.userpid = userpid;
      }, this));

      if(!this.userpid) {
        if(this.model.get('userpids').length > 0)
          this.userpid = this.model.get('userpids')[this.model.get('userpids').length-1];
      }

      ModalsConfirmationView.prototype.initialize.apply(this, arguments);
    },

    onShow: function () {
      var userpids = [];
      if(this.userpid)
        userpids.push(this.userpid);
      this.usersView = new UsersView({
        model: new Backbone.Model({userpids: userpids}),
        multiselect: false,
        tabindex: 1,
        onSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          this.userpid = user.toJSON();
          deferred.resolve();
          return deferred;
        }, this),
        onUnSelected: _.bind(function () {
          var deferred = new $.Deferred();
          this.userpid = null;
          deferred.resolve();
          return deferred;
        }, this)
      });
      this.getRegion('userRegion').show(this.usersView);

      //----------------
      App.request('order:types').done(_.bind(function (types) {
        var collection = new Backbone.Collection();
        _.each(types, _.bind(function (type) {
            var model = new Backbone.Model({
              id: type.code,
              value: type.name
            });
            collection.add(model);
        }, this));

        this.typesView = new SelectView({
          collection: collection,
          tabindex: 2
        });
        this.typesRegion.show(this.typesView);
        this.typesView.setSelected(this.type);
        this.typesView.on('change', _.bind(function () {
          this.type = this.typesView.getSelected().get('id');
          this.status = null;
          this.renderStatus();
        }, this));
      }, this));

      //----------------
      this.statusesView = new SelectView({
        collection: this.statusesCollection,
        tabindex: 3
      });
      this.statusesRegion.show(this.statusesView);
      this.renderStatus();

      //----------------
      this.copyCommentsView = new CheckboxView({
        title: '',
        value: true,
        text: _.i18n('common.duplicateComment'),
        tabindex: 4
      });
      this.copyCommentsRegion.show(this.copyCommentsView);

      //----------------
      ModalsConfirmationView.prototype.onShow.apply(this, arguments);
    },

    renderStatus: function() {
      App.request('orders:statuses', this.type).done(_.bind(function (statuses) {
        this.statusesCollection.reset();
        _.each(statuses.models, _.bind(function (status) {
          var model = new Backbone.Model({
            id: status.get('statusConst'),
            value: status.get('status')
          });
          this.statusesCollection.add(model);
        }, this));

        if(this.status)
          this.statusesView.setSelected(this.status);
        else
          this.statusesView.setSelected(this.statusesCollection.models[0].get('id'));
      }, this));
    },

    onConfirm: function () {
      this.load(true, _.i18n('orders.duplicate.saving'));
      this.model.duplicate({
        refUser: this.userpid,
        commandType: this.typesView.getSelected().get('id'),
        status: this.statusesView.getSelected().get('id')
      }, {copyComments: this.copyCommentsView.getValue()}).done(_.bind(function (newOrder) {
        this.load(false);
        Orders.showOrder(newOrder.value, '');
        ModalsConfirmationView.prototype.onConfirm.apply(this, arguments);
      }, this));
    },

    // Static methods
    open: function (model) {
      require([
        'windows',
        'ordersDuplicateView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: model
            });
          }, this),
          title: _.i18n('orders.duplicate.title') + ': ' + model.get('purchaseOrderId'),
          modal: true,
          minWidth: 600,
          maxWidth: 800
        });
      });
    }
  });
});
