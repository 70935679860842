/**
 * Created by BFR on 06/09/2022.
 */
define('ordersDetailPriceV2PriceView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'orderUtils',
  'template!ordersDetailPriceV2PriceView'
], function (
  module, Marionette,
  $,
  _,
  OrderUtils,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,
    tagName: 'tr',

    ui: {
      inputCustomUnit: '.input-price-unit',
      inputCustomTotal: '.input-price-total',
      btnSaveChange: '.btn-save-change',
      lblSPrice: '.lbl-sprice',
      txtSPrice: '.txt-sprice',
      lblSUPrice: '.lbl-suprice',
      txtSUPrice: '.txt-suprice',
      lblPrice: '.lbl-price',
      txtPrice: '.txt-price'
    },

    events: {
      'change @ui.txtSPrice': 'onInputCustomTotalChange',
      'focusout @ui.txtSPrice': 'onInputCustomTotalChange',
      'keydown @ui.txtSPrice': 'onTxtSPriceKeyPress',
      'change @ui.txtSUPrice': 'onInputCustomTotalUnitChange',
      'focusout @ui.txtSUPrice': 'onInputCustomTotalUnitChange',
      'keydown @ui.txtSUPrice': 'onTxtSUPriceKeyPress',
      'change @ui.txtPrice': 'onInputCustomBTotalChange',
      'focusout @ui.txtPrice': 'onInputCustomBTotalChange',
      'keydown @ui.txtPrice': 'onTxtPriceKeyPress',
      'click @ui.btnSaveChange': 'onBtnSaveChangeClick',
      'click @ui.lblSPrice': 'onLblSPriceClick',
      'click @ui.lblSUPrice': 'onLblSUPriceClick',
      'click @ui.lblPrice': 'onLblPriceClick'
    },

    onLblPriceClick: function () {
      this.ui.lblPrice.hide();
      this.ui.txtPrice.show();
      this.ui.txtPrice.focus();
      this.ui.txtPrice.select();
    },

    onLblSPriceClick: function () {
      this.ui.lblSPrice.hide();
      this.ui.txtSPrice.show();
      this.ui.txtSPrice.focus();
      this.ui.txtSPrice.select();
    },

    onLblSUPriceClick: function () {
      this.ui.lblSUPrice.hide();
      this.ui.txtSUPrice.show();
      this.ui.txtSUPrice.focus();
      this.ui.txtSUPrice.select();
    },

    onTxtSPriceKeyPress: function (e) {
      var value = this.ui.txtSPrice.val();
      var valueWithDot = value.replace(',', '.');
      this.ui.txtSPrice.val(valueWithDot);

      var keyCode = e.keyCode || e.which;
      if (keyCode === 9 || keyCode === 13) {
        if (e.shiftKey) {
          setTimeout(_.bind(this.trigger, this), 100, 'select:previous:selling');
        } else {
          setTimeout(_.bind(this.trigger, this), 100, 'select:next:selling');
        }
      }
    },

    onTxtSUPriceKeyPress: function (e) {
      var value = this.ui.txtSUPrice.val();
      var valueWithDot = value.replace(',', '.');
      this.ui.txtSUPrice.val(valueWithDot);

      var keyCode = e.keyCode || e.which;
      if (keyCode === 9 || keyCode === 13) {
        if (e.shiftKey) {
          setTimeout(_.bind(this.trigger, this), 100, 'select:previous:selling-unit');
        } else {
          setTimeout(_.bind(this.trigger, this), 100, 'select:next:selling-unit');
        }
      }
    },

    onTxtPriceKeyPress: function (e) {
      var value = this.ui.txtPrice.val();
      var valueWithDot = value.replace(',', '.');
      this.ui.txtPrice.val(valueWithDot);

      var keyCode = e.keyCode || e.which;
      if (keyCode === 9 || keyCode === 13) {
        if (e.shiftKey) {
          setTimeout(_.bind(this.trigger, this), 100, 'select:previous');
        } else {
          setTimeout(_.bind(this.trigger, this), 100, 'select:next');
        }
      }
    },

    onInputCustomBTotalChange: function () {
      this.ui.lblPrice.html(this.ui.txtPrice.val() + '€');
      this.ui.lblPrice.show();
      this.ui.txtPrice.hide();
      if (!this.model.collection.currentBuying.priceCustom) {
        this.model.collection.currentBuying.priceCustom = {};
      }
      if (!this.model.collection.currentBuying.priceCustom.totalPriceScale) {
        this.model.collection.currentBuying.priceCustom.totalPriceScale = [];
      }

      var customPriceScale = _.findWhere(this.model.collection.currentBuying.priceCustom.totalPriceScale,
        {quantity: this.model.get('quantity')});
      if (!customPriceScale) {
        this.model.collection.currentBuying.priceCustom.totalPriceScale.push({
          quantity: Number(this.model.get('quantity')),
          price: this.ui.txtPrice.val()
        });
      } else {
        customPriceScale.price = this.ui.txtPrice.val();
      }
      this.model.get('buying').total = this.ui.txtPrice.val();
      this.model.get('buying').totalUnit = (Number(this.ui.txtPrice.val()) / this.model.get('quantity')).toFixed(2);

      if (this.model.get('current')) {
        this.model.collection.currentBuying.priceCustom.totalPrice = this.ui.txtPrice.val();
      }
      this.render();
      this.trigger('display:save');
    },

    onInputCustomTotalUnitChange: function () {
      this.ui.lblSPrice.html(this.ui.txtSUPrice.val() + '€');
      this.ui.txtSPrice.val(Number(this.ui.txtSUPrice.val().replace(',', '.')) * this.model.get('quantity'));
      this.onInputCustomTotalChange();
    },

    onInputCustomTotalChange: function () {
      var newVal = this.ui.txtSPrice.val().replace(',', '.');
      this.ui.lblSPrice.html(newVal + '€');
      this.ui.lblSPrice.show();
      this.ui.txtSPrice.hide();
      if (!this.model.collection.current.priceCustom) {
        this.model.collection.current.priceCustom = {};
      }
      if (!this.model.collection.current.priceCustom.totalPriceScale) {
        this.model.collection.current.priceCustom.totalPriceScale = [];
      }
      var customPriceScale = _.findWhere(this.model.collection.current.priceCustom.totalPriceScale, {quantity: this.model.get('quantity')});
      if (!customPriceScale) {
        this.model.collection.current.priceCustom.totalPriceScale.push({
          quantity: Number(this.model.get('quantity')),
          price: this.ui.txtSPrice.val().replace(',', '.')
        });
      } else {
        customPriceScale.price = newVal;
      }
      this.model.get('selling').total = newVal;
      this.model.get('selling').totalUnit = (Number(newVal) / this.model.get('quantity')).toFixed(2);

      if (this.model.get('current')) {
        this.model.collection.current.priceCustom.totalPrice = newVal;
      }
      this.render();
      this.trigger('display:save');
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      if (templateData.selling) {
        if (!templateData.current &&
          this.model.collection.current.priceCustom &&
          this.model.collection.current.priceCustom.totalPriceScale) {
          var customPriceScale = _.findWhere(this.model.collection.current.priceCustom.totalPriceScale, {
            quantity: this.model.get('quantity')
          });
          if (customPriceScale) {
            templateData.selling.priceCustom = {
              totalPrice: customPriceScale.price
            };
          }
        }

        if (!templateData.current &&
          this.model.collection.currentBuying.priceCustom &&
          this.model.collection.currentBuying.priceCustom.totalPriceScale) {
          var customBPriceScale = _.findWhere(this.model.collection.currentBuying.priceCustom.totalPriceScale, {
            quantity: this.model.get('quantity')
          });
          if (customBPriceScale && templateData.buying) {
            templateData.buying.priceCustom = {
              totalPrice: customBPriceScale.price
            };
          }
        }

        if(templateData.buying && templateData.selling) {
          templateData.selling.unitMargin = Number(templateData.selling.totalUnit - templateData.buying.totalUnit).toFixed(2);
          templateData.selling.percentMargin = Number(((templateData.selling.total -
            templateData.buying.total) / templateData.selling.total) * 100).toFixed(2);
          templateData.selling.totalMargin = Number(templateData.selling.total -
            templateData.buying.total).toFixed(2);
        }       
      }
      
      if (templateData.currentSelling && templateData.currentBuying) {
        templateData.currentSelling.unitMargin = Number(templateData.currentSelling.totalUnit -
          templateData.currentBuying.totalUnit).toFixed(2);
        templateData.currentSelling.percentMargin = Number(((templateData.currentSelling.total -
          templateData.currentBuying.total) / templateData.currentSelling.total) * 100).toFixed(2);
        templateData.currentSelling.totalMargin = Number(templateData.currentSelling.total -
          templateData.currentBuying.total).toFixed(2);
      }

      return templateData;
    },

    onRender: function () {
      if (this.model.get('current')) {
        this.$el.css('background-color', 'rgb(212,231,214)');
      } else {
        this.$el.css('background-color', '#ffffff');
      }
    }

  });
});
