/**
 * Created by BFR on 06/09/2022.
 */
define('ordersDetailPriceV2PricesView',[
  'module',
  'marionette',
  'jquery',
  'backbone',
  'underscore',
  'template!ordersDetailPriceV2PricesView',
  'ordersDetailPriceV2PriceView',
  'customQuantitiesView'
], function (
  module, Marionette,
  $,
  Backbone,
  _,
  viewTpl,
  orderPriceV2ItemView,
  CustomQuantitiesView
) {
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.items-region',
    childView: orderPriceV2ItemView,

    childEvents: {
      'display:save': function () {
        this.ui.btnSave.show();
      },
      'select:next': 'onSelectNextChild',
      'select:previous': 'onSelectPreviousChild',
      'select:next:selling': 'onSelectNextSellingChild',
      'select:previous:selling': 'onSelectPreviousSellingChild',
      'select:next:selling-unit': 'onSelectNextSellingUnitChild',
      'select:previous:selling-unit': 'onSelectPreviousSellingUnitChild'
    },

    ui: {
      addCostDesc: '.add-cost-desc',
      addCost: '.add-cost',
      btnSaveAddCost: '.btn-save-add-cost',
      btnSave: '.btn-save-price',
      btnExpressDelivery: '.btn-express-delivery'
    },

    events: {
      'change @ui.addCostDesc': 'onAddCostChange',
      'change @ui.addCost': 'onAddCostChange',
      'click @ui.btnSave': 'onBtnSaveClick',
      'click .btn-custom-scales': 'onBtnCustomScalesClick',
      'click .btn-reset-price': 'onBtnResetPriceClick',
      'click @ui.btnExpressDelivery': 'onBtnExpressDeliveryClick'
    },

    onSelectPreviousChild: function (childView) {
      var previous;
      for (var property in this.children._views) {
        if (property === childView.cid) {
          if (previous) {
            previous.onLblPriceClick();
          }
        } else {
          previous = this.children._views[property];
        }
      }
    },

    onSelectNextChild: function (childView) {
      var useNext = false;
      for (var property in this.children._views) {
        if (useNext) {
          this.children._views[property].onLblPriceClick();
          return;
        }
        if (property === childView.cid) {
          useNext = true;
        }
      }
    },

    onSelectPreviousSellingChild: function (childView) {
      var previous;
      for (var property in this.children._views) {
        if (property === childView.cid) {
          if (previous) {
            previous.onLblSPriceClick();
          }
        } else {
          previous = this.children._views[property];
        }
      }
    },

    onSelectNextSellingChild: function (childView) {
      var useNext = false;
      for (var property in this.children._views) {
        if (useNext) {
          this.children._views[property].onLblSPriceClick();
          return;
        }
        if (property === childView.cid) {
          useNext = true;
        }
      }
    },

    onSelectPreviousSellingUnitChild: function (childView) {
      var previous;
      for (var property in this.children._views) {
        if (property === childView.cid) {
          if (previous) {
            previous.onLblSUPriceClick();
          }
        } else {
          previous = this.children._views[property];
        }
      }
    },

    onSelectNextSellingUnitChild: function (childView) {
      var useNext = false;
      for (var property in this.children._views) {
        if (useNext) {
          this.children._views[property].onLblSUPriceClick();
          return;
        }
        if (property === childView.cid) {
          useNext = true;
        }
      }
    },

    onBtnCustomScalesClick: function () {
      var scales = this.options.prices.customScales;
      if (!scales || !scales.length) {
        scales = [{quantity: 50}];
      }
      this.customQuantitiesView = new CustomQuantitiesView({
        collection: new Backbone.Collection(scales)
      });

      this.customQuantitiesView.show({
        title: 'Custom Scales',
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default btn-flat',
            callback: function () {
              return true;
            }
          },
          success: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary',
            callback: _.bind(function () {
              this.trigger('show-loading', true);
              this.model.setCustomScales(this.customQuantitiesView.collection.toJSON()).done(_.bind(function () {
                this.trigger('get-price-for-order');
              }, this));
            }, this)
          }
        }
      });
    },

    onBtnResetPriceClick: function () {
      this.trigger('show-loading', true);
      this.model.resetPrice().done(_.bind(function () {
        this.model.resetBuyingPrice().done(_.bind(function () {
          this.trigger('get-price-for-order');
        }, this));
      }, this));
    },

    onBtnSaveClick: function () {
      var updateBuying = false, updateSelling = false;
      if (this.currentBuying.priceCustom && this.currentBuying.priceCustom.totalPriceScale) {
        //fill missing custom with current values
        _.each(this.collection.models, _.bind(function (price) {
          if (price.get('buying') && price.get('buying').total) {
            var found = _.findWhere(this.currentBuying.priceCustom.totalPriceScale, {quantity: Number(price.get('buying').quantity)});
            if (!found) {
              if (!this.currentBuying.priceCustom.totalPriceScale) {
                this.currentBuying.priceCustom.totalPriceScale = [];
              }
              this.currentBuying.priceCustom.totalPriceScale.push({
                quantity: Number(price.get('buying').quantity),
                price: Number(price.get('buying').total)
              });
            }
          }
        }, this));

        this.currentBuying.priceCustom.totalPriceScale = _.sortBy(this.currentBuying.priceCustom.totalPriceScale, 'quantity');
        updateBuying = true;
      }

      if (this.current.priceCustom && this.current.priceCustom.totalPriceScale) {
        //fill missing custom with current values
        _.each(this.collection.models, _.bind(function (price) {
          if (price.get('selling') && price.get('selling').total) {
            var found = _.findWhere(this.current.priceCustom.totalPriceScale, {quantity: Number(price.get('selling').quantity)});
            if (!found) {
              this.current.priceCustom.totalPriceScale.push({
                quantity: Number(price.get('selling').quantity),
                price: Number(price.get('selling').total)
              });
            }
          }
        }, this));

        this.current.priceCustom.totalPriceScale = _.sortBy(this.current.priceCustom.totalPriceScale, 'quantity');
        updateSelling = true;
      }

      if (this.current.priceCustom && (this.current.priceCustom.addDesc || this.current.priceCustom.addCost)) {
        updateSelling = true;
      }

      if (updateBuying) {
        this.model.getPriceBuyingCustom(this.currentBuying.priceCustom, true).done(_.bind(function () {
          if (updateSelling) {
            this.model.getPriceCustom(this.current.priceCustom, true).done(_.bind(function () {
              this.ui.btnSave.hide();
              this.render();
            }, this));
          } else {
            this.ui.btnSave.hide();
            this.render();
          }
        }, this));
      } else if (updateSelling) {
        this.model.getPriceCustom(this.current.priceCustom, true).done(_.bind(function () {
          this.ui.btnSave.hide();
          this.render();
        }, this));
      }
    },

    onAddCostChange: function () {
      if (!this.current.priceCustom) {
        this.current.priceCustom = {};
      }
      this.current.priceCustom.addDesc = this.ui.addCostDesc.val();
      this.current.priceCustom.addCost = this.ui.addCost.val();
      this.ui.btnSave.show();
    },

    onBtnExpressDeliveryClick: function () {
      this.model.updateExpressShipping(!this.model.get('expressShip')).done(_.bind(function () {
        this.model.fetch().done(_.bind(function () {
          //this.render();
          this.onBtnResetPriceClick();
        }, this));
      }, this));
    },

    getCurrent: function (current) {
      return {
        quantity: Number(current.quantity),
        currentBuying: this.options.currentPrices,
        currentSelling: this.options.currentRsPrices,
        buying: this.options.prices,
        selling: current,
        current: true
      };
    },

    serializeData: function () {
      var templateData = {};
      if (this.current.priceCustom) {
        templateData.addDesc = this.current.priceCustom.addDesc;
        templateData.addCost = this.current.priceCustom.addCost;
      } else {
        templateData.addDesc = '';
        templateData.addCost = '';
      }

      if(this.model.get('expressShip'))
        templateData.expressShippingButtonText = _.i18n('order.expressDelivery.remove');
      else
        templateData.expressShippingButtonText = _.i18n('order.expressDelivery.allow');

      return templateData;
    },

    initialize: function () {
      var priceArray = [];

      //Build price object.
      if (this.options.rsPrices && this.options.rsPrices.scale) {
        this.current = this.options.rsPrices;
        this.currentBuying = this.options.prices;
        var currentInserted = false;

        var scales = this.options.currentPrices.scale;
        if (this.options.factorsV2 && !this.options.currentPrices.customScales) {
          scales = _.filter(this.options.currentPrices.scale, _.bind(function (scale) {
            if (Number(scale.total) === 0 || (Number(scale.total) > this.options.factorsV2.param2 &&
              (!this.options.factorsV2.param3 ||
                this.options.factorsV2.param3 === 0 ||
                Number(scale.total) < this.options.factorsV2.param3))) {
              return true;
            }
            return false;
          }, this));
        }

        _.each(scales, _.bind(function (currentPrices, index) {
          if (currentPrices.quantity && (currentPrices.quantity === this.current.quantity ||
            (currentInserted === false && Number(currentPrices.quantity) > Number(this.current.quantity)))) {
            priceArray.push(this.getCurrent(this.current));
            currentInserted = true;
          }
          if (currentPrices.quantity && Number(currentPrices.quantity) !== Number(this.current.quantity)) {
            priceArray.push({
              quantity: Number(currentPrices.quantity),
              currentBuying: _.findWhere(this.options.currentPrices.scale, {quantity: currentPrices.quantity}),
              currentSelling: _.findWhere(this.options.currentRsPrices.scale, {quantity: currentPrices.quantity}),
              buying: _.findWhere(this.options.prices.scale, {quantity: currentPrices.quantity}),
              selling: _.findWhere(this.options.rsPrices.scale, {quantity: currentPrices.quantity}),
              current: false
            });
          }
          if (!currentInserted && this.options.currentPrices.scale.length === index + 1) {
            priceArray.push(this.getCurrent(this.current));
          }
        }, this));
      }

      var emptyPrice = {
          productCode: '0',
          productPrice: '0',
          productUnitPrice: '0',
          quantity: '0',
          total: '0',
          totalSetupCost: '0',
          totalUnit: '0',
          totalUnitCost: '0',
          impt: [],
          shippingCost: '0',
          fromPimData: false,
          expressShippingCost: '0'
        };

      _.each(this.options.prices.scale, _.bind(function (currentPrices) {
        if (!_.findWhere(priceArray, {quantity: Number(currentPrices.quantity)})) {
          priceArray.push({
            quantity: Number(currentPrices.quantity),
            currentBuying: _.findWhere(this.options.currentPrices.scale, {quantity: currentPrices.quantity}) || emptyPrice,
            currentSelling: _.findWhere(this.options.currentRsPrices.scale, {quantity: currentPrices.quantity}) || emptyPrice,
            buying: _.findWhere(this.options.prices.scale, {quantity: currentPrices.quantity}),
            selling: _.findWhere(this.options.rsPrices.scale, {quantity: currentPrices.quantity}),
            current: Number(this.current.quantity) === Number(currentPrices.quantity)
          });
        }
      },this));

      priceArray = _.sortBy(priceArray, 'quantity');
      this.collection = new Backbone.Collection(priceArray);
      this.collection.model = this.model;
      this.collection.current = this.current;
      this.collection.currentBuying = this.currentBuying;
    }
  });
});
